import React from 'react'

import { useQuery } from 'react-query'

import { Box } from '@material-ui/core'

import CategoryCard from '~/components/cards/CategoryCard'
import AdsSlot from '~/components/shared/AdSlot'
import Section from '~/components/shared/Section'

import EmpriseGroup from '~/services/EmpriseGroup'
import Paths from '~/utils/paths'

import { linksCategories } from '~/data/linksCategories'

import { IS_BOAT, IS_RV } from '~/brands'
import { CACHE_KEYS } from '~/constants'
import { IImage, IOptions } from '~/interfaces/shared'

const CategoriesSection = () => {
  const { data } = useQuery<(IOptions & { logo: IImage })[]>(
    CACHE_KEYS.types,
    EmpriseGroup.getMainCategories,
    {
      enabled: IS_RV,
      retry: false,
      retryOnMount: false,
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchIntervalInBackground: false,
      refetchInterval: false
    }
  )

  return (
    <Section
      bgcolor="bg.primary.main"
      linkHref={Paths.search.index}
      title="Categories"
      empty={!data?.length}
      unmountOnEmpty
    >
      <Box display="flex" justifyContent="center" flexWrap="wrap">
        {IS_RV &&
          data?.map((category) => {
            return (
              <CategoryCard
                m={1}
                icon={category?.logo?.url}
                key={category.name}
                category={category.name}
                href={Paths.search.buildSearch({ by_type: category.name })}
              />
            )
          })}
        {IS_BOAT &&
          linksCategories.map((category) => {
            return (
              <CategoryCard
                m={1}
                icon={category.logo}
                key={category.name}
                category={category.name}
                href={category.href}
              />
            )
          })}
      </Box>
      <Box display="flex" justifyContent="center" flexWrap="wrap" mt={9}>
        <AdsSlot
          slotId="gam_banner_pos1"
          sizes={[[728, 90]]}
          sizeMapping={[
            {
              viewport: [1280, 0],
              sizes: [
                [728, 90],
                [970, 90]
              ]
            },
            { viewport: [895, 0], sizes: [[728, 90], 'fluid'] },
            {
              viewport: [0, 0],
              sizes: [[300, 250], [320, 100], [320, 50], [300, 50], 'fluid']
            }
          ]}
          targetingArguments={{ pos: '1', native: 'infeed' }}
        />
      </Box>
    </Section>
  )
}

export default React.memo(CategoriesSection)
